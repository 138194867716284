import React from 'react';

import { useCheckoutStore, useMenuStore } from 'mycheck-core';

import { KcalLAdultLabel } from '../KcalLAdultLabel/KcalLAdultLabel';
import { MenuItem } from '../MenuItem/MenuItem';

import { DesktopMenuCategoryHeader } from './DesktopMenuCategoryHeader';
import styles from './MenuDesktop.module.scss';
import { observer } from 'mobx-react';
import { useMenuTabs } from 'plugins/MenuPlugin/hooks/useMenuTabs';
import InView from 'react-intersection-observer';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';
import { getKioskStatus } from 'core/core/store/kiosk/slice';
import { useAppSelector } from 'core/core/store/store';

export const MenuDesktop: React.FC = observer(() => {
  const isKioskMode = useAppSelector(getKioskStatus);
  const MenuStore = useMenuStore();
  const CheckoutStore = useCheckoutStore();
  const { onChangeVisibility } = useMenuTabs();

  return (
    <div
      className={styles.root}
      id="menu-desktop-root"
      style={{ height: `calc(100vh ${isKioskMode ? '' : '-196px'})` }}
    >
      {MenuStore.menu.map((category) => (
        <InView
          onChange={(inView) => onChangeVisibility(category, inView)}
          threshold={0.1}
          key={category.id}
        >
          {({ ref }) => (
            <>
              <DesktopMenuCategoryHeader category={category} ref={ref} />
              <div className={styles.itemWrapper}>
                {category.items?.map((item) => (
                  <MenuItem
                    key={item.id}
                    item={item}
                    category={category as MenuCategoryType & MenuSubcategory}
                    counter={CheckoutStore.getOrderItemNumber(item.id)}
                  />
                ))}
              </div>
              {category.subcategories?.map((subcategory) => (
                <React.Fragment key={subcategory.id}>
                  <DesktopMenuCategoryHeader
                    category={subcategory}
                    subcategory
                  />

                  <div className={styles.itemWrapper}>
                    {subcategory.items?.map((item) => (
                      <MenuItem
                        key={item.id}
                        item={item}
                        category={
                          category as MenuCategoryType & MenuSubcategory
                        }
                        counter={CheckoutStore.getOrderItemNumber(item.id)}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </InView>
      ))}
      <KcalLAdultLabel />
    </div>
  );
});

MenuDesktop.displayName = 'MenuDesktop';
