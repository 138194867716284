import { Formik } from 'formik';
import React from 'react';
import { KioskModal } from '../common/Modal/KioskModal';
import {
  useNavigation,
  useTranslation,
  emailValidationForm,
} from 'mycheck-core';
import * as yup from 'yup';
import { CustomInput } from '@components';
import { useAppDispatch } from 'core/core/store/store';
import { setKioskEmailReceipt } from 'core/core/store/kiosk/slice';

export const KioskReceipt = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const getValidationSchema = () =>
    yup.object().shape({
      email: emailValidationForm(
        t('kiosk.kioskReceiptModal.invalidEmail'),
      ).required(),
    });

  const navigateToCheckout = () => {
    navigation.push('/menu/checkout');
    onClose();
  };

  const onSubmit = ({ email }) => {
    dispatch(setKioskEmailReceipt(email));
    navigateToCheckout();
  };

  const backToMenu = () => {
    dispatch(setKioskEmailReceipt(null));
    navigateToCheckout();
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={getValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, submitForm, values: { email } }) => (
        <KioskModal
          onClose={onClose}
          primaryButtonText={t('kiosk.kioskReceiptModal.primaryButton')}
          secondaryButtonText={t('kiosk.kioskReceiptModal.secondaryButton')}
          title={t('kiosk.kioskReceiptModal.title')}
          headerTitle={t('kiosk.kioskReceiptModal.headerTitle')}
          secondaryButtonClick={backToMenu}
          primaryButtonClick={submitForm}
          testId="kiosk-receipt-modal"
        >
          <CustomInput
            customStyle={{
              margin: '0 0 24px',
              padding: 0,
              textAlign: 'left',
            }}
            onChange={handleChange}
            value={email}
            error={!!errors.email}
            errorMessage={t('kiosk.kioskReceiptModal.invalidEmail')}
            name={'email'}
            placeholder={t('kiosk.kioskReceiptModal.emailLabel')}
            testId="receipt-mail"
          />
        </KioskModal>
      )}
    </Formik>
  );
};
