import { Background } from '@components';
import React, { CSSProperties, PropsWithChildren, useContext } from 'react';
import { MenuPageContext } from './MenuPageContext';

export interface MenuPageBodyProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

export const MenuPageBody: React.FC<MenuPageBodyProps> = ({
  className,
  children,
  style,
}) => {
  const { isLoading } = useContext(MenuPageContext);

  return (
    <Background secondary className={className} style={style}>
      {!isLoading && children}
    </Background>
  );
};

MenuPageBody.displayName = 'MenuPageBody';
