import React from 'react';
import { Background, CustomIcon, Overlay } from '@components';
import { useNavigation, useTranslation } from 'mycheck-core';
import styles from './KioskCancelTransaction.module.scss';

export const KioskCancelTransaction = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const onClose = () => {
    navigation.push('/menu');
  };

  return (
    <Overlay
      className={styles.root}
      testId="kiosk-cancel-transaction"
      onClickOutside={onClose}
      isPopup
      isKioskMode
    >
      <Background>
        <div>
          <div onClick={onClose}>
            <CustomIcon
              name="x"
              style={{
                width: '16px',
                height: '16px',
                cursor: 'pointer',
                marginLeft: 'auto',
              }}
            />
          </div>
        </div>
        <div className={styles.title}>
          {t('kiosk.kioskTransactionCancelModal.title')}
        </div>
        <div className={styles.headerTitle}>
          {t('kiosk.kioskTransactionCancelModal.headerTitle')}
        </div>
      </Background>
    </Overlay>
  );
};
