import { KioskState } from './slice';

const getKioskDeviceId = (state: KioskState) => state.deviceId;
const getKioskStatus = (state: KioskState) => state.isEnabled;
const getKioskFailedFlow = (state: KioskState) => state.isFlowFailed;
const getKioskEmailReceipt = (state: KioskState) => state.emailReceipt;
const getKioskObfuscatedEmailReceipt = (state: KioskState) =>
  state.obfuscatedEmailReceipt;

export const selectors = {
  getKioskDeviceId,
  getKioskStatus,
  getKioskFailedFlow,
  getKioskEmailReceipt,
  getKioskObfuscatedEmailReceipt,
};
