import React from 'react';

import styles from './KioskCalculationSection.module.scss';
import { CardSummary } from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { PriceFormatter } from 'components/PriceFormatter/PriceFormatter';

const calculateColumn = (
  value: string | number,
  description: string,
  price: boolean = false,
) => (
  <figure className={styles.column}>
    {price ? <PriceFormatter value={Number(value)} withoutSpace /> : value}
    <figcaption className={styles.columnCaption}>{description}</figcaption>
  </figure>
);

export const KioskCalculationSection = ({
  summaryObject,
}: {
  summaryObject: Partial<CardSummary>;
}) => {
  return (
    <section className={styles.root}>
      {calculateColumn(summaryObject.itemsQuantity, 'Items')}
      {calculateColumn(summaryObject.subtotal, 'Sub-Total', true)}
      {calculateColumn(summaryObject.tax, 'Tax (included)', true)}
    </section>
  );
};
