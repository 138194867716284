import React, {
  useMemo,
  useRef,
  useState,
  type CSSProperties,
  type PropsWithChildren,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { generateUID, MyCheckApp, useClickOutside } from 'mycheck-core';

import { Paper } from '../Paper/Paper';

import styles from './Overlay.module.scss';

interface OverlayProps extends PropsWithChildren {
  withoutModal?: boolean;
  className?: string;
  width?: number | string;
  height?: number | string;
  isPopup?: boolean;
  testId?: string;
  onClickOutside?: (event: MouseEvent) => void;
  overlayStyle?: CSSProperties;
  paperStyle?: CSSProperties;
  isKioskMode?: boolean;
}

export const Overlay: React.FC<OverlayProps> = ({
  children,
  withoutModal,
  className,
  height = 640,
  width = 480,
  onClickOutside,
  testId = '',
  isPopup,
  overlayStyle = {},
  paperStyle = undefined,
  isKioskMode = false,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const popupStyle = get(_config, 'general.popup.background', {});
    const _paperStyle: CSSProperties = isPopup
      ? {
          width: popupStyle.width,
          height: 'auto',
          textAlign: 'center',
        }
      : {
          maxHeight: height,
          maxWidth: width,
        };

    return {
      _paperStyle: paperStyle || _paperStyle,
    };
  }, [location.search, paperStyle]);

  const [guid] = useState(generateUID());

  const handleClickOutside = (event: MouseEvent) => {
    if (onClickOutside) {
      onClickOutside(event);
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, handleClickOutside, { overlayId: guid });

  return (
    <div
      data-test-id={`${testId}-overlay`}
      className={styles.overlay}
      data-overlay={guid}
      style={overlayStyle}
    >
      {withoutModal ? (
        <div data-test-id={testId} ref={ref}>
          {children}
        </div>
      ) : (
        <Paper
          id={`${testId}-overlay-paper`}
          className={classnames(styles.body, className && className)}
          style={{
            ...config._paperStyle,
            ...(isKioskMode && { width: '512px' }),
          }}
          ref={ref}
        >
          {children}
        </Paper>
      )}
    </div>
  );
};
