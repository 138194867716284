import { IdleRouteConfigType, IdleTimeoutType } from './types';

export const AutoRedirectTimeoutSeconds = 30;
export const idleRoutingConfig: IdleRouteConfigType = {
  [IdleTimeoutType.None]: [
    '/menu/checkout/tapCard',
    '/menu/checkout/loading',
    '/menu/cancelTransaction',
    '/menu/order',
    '/menu/confirm',
  ],
  [IdleTimeoutType.Auto]: ['/menu/cancelOrder'],
  [IdleTimeoutType.Modal]: [
    '/menu',
    '/menu/:id',
    '/menu/:id/:editId',
    '/menu/checkout',
    '/menu/checkout/wentWrong',
  ],
};
