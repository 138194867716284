import React from 'react';
import { KioskModal } from '../common/Modal/KioskModal';
import { useCheckoutStore, useNavigation, useTranslation } from 'mycheck-core';

export const KioskCancelOrder = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const CheckoutStore = useCheckoutStore();

  const onClose = () => {
    navigation.push('/menu');
  };

  const removeOrders = () => {
    CheckoutStore.removeAllOrderItems();
    onClose();
  };

  return (
    <KioskModal
      onClose={onClose}
      primaryButtonText={t('kiosk.kioskCancelOrderModal.primaryButton')}
      secondaryButtonText={t('kiosk.kioskCancelOrderModal.secondaryButton')}
      title={t('kiosk.kioskCancelOrderModal.title')}
      secondaryButtonClick={onClose}
      primaryButtonClick={removeOrders}
      testId="kiosk-cancel-order-modal"
      withCenterTitle
    >
      {t('kiosk.kioskCancelOrderModal.content')}
    </KioskModal>
  );
};
