import React, { useMemo, type PropsWithChildren } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './Modal.module.scss';

enum ModalSizes {
  md = 640,
}

interface ModalProps extends PropsWithChildren {
  onClose?: () => void;
  title?: string;
  size?: ModalSizes;
  testId?: string;
  open: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  title,
  testId,
  size = ModalSizes.md,
  open = false,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const backgroundColor = get(_config, 'palette.primary', '');

    return {
      backgroundColor,
    };
  }, [location.search]);

  if (!open) return null;

  return (
    <div className={styles.root} data-test-id={testId}>
      <div className={styles.overlay} onClick={onClose}></div>

      <div className={styles.content} style={{ maxWidth: size }}>
        <div
          className={styles.header}
          style={{ backgroundColor: config.backgroundColor }}
        >
          {title}

          <button
            className={styles.closeButton}
            onClick={onClose}
            type="button"
          >
            <CustomIcon name="x" />
          </button>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};
