import { ApiClient } from 'mycheck-core';
import {
  HttpCheckoutApiRequest,
  HttpCheckoutApiResponse,
  ICheckoutApi,
} from './types/CheckoutTypes';

const CheckoutUrls = {
  cartCreate: '/restaurants/api/v3/cart',
  cartUpdate: (id: string) => `/restaurants/api/v3/cart/${id}`,
  cartCheckout: (id: string) => `/restaurants/api/v3/cart/${id}/checkout`,
  cartDelete: (id: string) => `/restaurants/api/v3/cart/${id}/checkout`,
  roomValidation: (model: HttpCheckoutApiRequest['roomValidation']) =>
    `/restaurants/api/v3/${model.bid}/roomChargeValidity?lastName=${model.lastName}&roomNumber=${model.roomNumber}`,
  roomValidity: (model: HttpCheckoutApiRequest['roomValidation']) =>
    `/hotels/api/v1/business/${model.bid}/reservations/roomValidity?lastName=${model.lastName}&roomNumber=${model.roomNumber}`,
  confirm3DSecure: (orderId: string, paymentId: number) =>
    `/restaurants/api/v3/order/${orderId}/payment/${paymentId}/transaction`,
  couponVerify: `rewards/api/v1/rewards/verify`,
  cancelTransaction: (orderId: string, paymentId: number) =>
    `/restaurants/api/v3/order/${orderId}/payment/${paymentId}/cancel`,
};

export const HTTP_CHECKOUT_API = 'HTTP_CHECKOUT_API';

export class HttpCheckoutApi implements ICheckoutApi {
  postCreateCart = async (
    model: HttpCheckoutApiRequest['updateCart'],
    accessToken: string,
  ) => {
    const response = await ApiClient.instance.post<
      HttpCheckoutApiRequest['updateCart'],
      HttpCheckoutApiResponse['iCreateNewCart']
    >(CheckoutUrls.cartCreate, model, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  };

  putUpdateCart = async (
    model: HttpCheckoutApiRequest['updateCart'],
    accessToken: string,
  ) => {
    const response = await ApiClient.instance.put<
      HttpCheckoutApiRequest['updateCart'],
      HttpCheckoutApiResponse['iCreateNewCart']
    >(CheckoutUrls.cartUpdate(model.id), model, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  };

  postCheckoutCart = async (
    model: HttpCheckoutApiRequest['checkoutCart'],
    accessToken: string,
  ) => {
    const response = await ApiClient.instance.post<
      HttpCheckoutApiRequest['checkoutCart'],
      HttpCheckoutApiResponse['iCheckoutCart']
    >(CheckoutUrls.cartCheckout(model.id), model, {
      headers: { Authorization: accessToken },
    });

    return response.data;
  };

  deleteCheckoutCart = async (cartId: string, accessToken: string) => {
    await ApiClient.instance.delete(CheckoutUrls.cartDelete(cartId), {
      headers: { Authorization: accessToken },
    });
  };

  roomValidation = async (
    model: HttpCheckoutApiRequest['roomValidation'],
    accessToken: string,
  ) => {
    const response = await ApiClient.instance.post<
      HttpCheckoutApiRequest['roomValidation'],
      HttpCheckoutApiResponse['iRoomValidation']
    >(CheckoutUrls.roomValidation(model), null, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  };

  roomValidity = async (
    model: HttpCheckoutApiRequest['roomValidation'],
    accessToken: string,
  ) => {
    const response = await ApiClient.instance.get<
      HttpCheckoutApiResponse['iRoomValidation']
    >(CheckoutUrls.roomValidity(model), null, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  };

  confirm3DSecure = async (
    orderId: string,
    paymentId: number,
    transactionId: string | number,
  ) => {
    await ApiClient.instance.put(
      CheckoutUrls.confirm3DSecure(orderId, paymentId),
      { transaction_id: transactionId },
    );
  };

  cancelTransaction = async (orderId: string, paymentId: number) => {
    await ApiClient.instance.post(
      CheckoutUrls.cancelTransaction(orderId, paymentId),
    );
  };
}
