import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FormikHelpers } from 'formik';
import { IFormikValues } from 'plugins/CheckoutPlugin/types/IFormikValues';

import { KioskCheckout } from '@components';
import { StandardCheckout } from 'plugins/CheckoutPlugin/components/StandardCheckout/StandardCheckout';
import {
  getKioskDeviceId,
  getKioskEmailReceipt,
  getKioskStatus,
  setKioskFlowFailed,
} from 'core/core/store/kiosk/slice';
import { useAppDispatch, useAppSelector } from 'core/core/store/store';
import {
  CardSummary,
  Coupon,
  Tax,
} from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import {
  GaService,
  isPast,
  isSameDay,
  MyCheckApp,
  PaymentConstants,
  startCartListening,
  startCartListeningRejected,
  startOrderListening,
  toUTCDateFormat,
  useAuthStore,
  useCheckoutStore,
  useDateTimeTrackerHandler,
  useErrorTrackerHandler,
  useGoBackToMenu,
  useLanguageStore,
  useLocation,
  useLocationStore,
  useMenuTrackerHandler,
  useNavigation,
  useNearestTime,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { TipStringValues, TipType } from '../../Constants';
import { IUIFieldSettings } from 'plugins/LocationPlugin/types/LocationTypes';
import { MODAL_HEADER_OFFSET } from 'plugins/MenuPlugin/pages/MenuPage/common/constants';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { KioskPostMessageEvent } from 'components/Kiosk/types';

export const CheckoutPage = () => {
  const isKioskMode = useAppSelector(getKioskStatus);
  const kioskEmailReceipt = useAppSelector(getKioskEmailReceipt);
  const kioskDeviceId = useAppSelector(getKioskDeviceId);
  const dispatch = useAppDispatch();
  const errorHandler = useErrorTrackerHandler();
  const { goBackToMenu } = useGoBackToMenu(true);
  const { confirmSlot } = useDateTimeTrackerHandler();
  const { nearestTime } = useNearestTime();
  const { isLg } = useWindowSize();
  const AuthStore = useAuthStore();
  const CheckoutStore = useCheckoutStore();
  const LanguageStore = useLanguageStore();
  const LocationStore = useLocationStore();
  const menuTracker = useMenuTrackerHandler();
  const navigation = useNavigation();
  const location = useLocation();
  const { t } = useTranslation();
  const isMounted = useRef(true);
  const [localPendingCalculations, setLocalPendingCalculations] =
    useState(true);
  const [isCheckoutFetching, setIsCheckoutFetching] = useState(false);
  const [summaryObject, setSummaryObject] = useState<Partial<CardSummary>>({
    subtotal: 0,
    total: 0,
  });
  const [taxesArray, setTaxesArray] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [guestOptions, setGuestOptions] = useState([]);
  const [paymentsMethodOpen, setPaymentsMethodOpen] = useState(false);

  const refFirebase = useRef<() => Promise<void>>();
  const refFirebaseReject = useRef<() => Promise<void>>();

  const handleErrorGoBack = () => goBackToMenu();

  const config = useMemo(() => {
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    return {
      supportMembers,
    };
  }, [location.search]);

  const onFirebaseCartUpdate = (value: any) => {
    if (value) {
      setTaxesArray(value.taxes || []);
      setSummaryObject({
        ...value.summery,
        service_charges: value.service_charges,
      });
      setLocalPendingCalculations(false);
    }
  };

  const onFirebaseCartRejected = (value: any) => {
    errorHandler.onError(
      { message: value.reason, items_not_in_stock: value.out_of_stock_items },
      () => {
        CheckoutStore.deleteItemAfterError(value.out_of_stock_items);
        handleErrorGoBack();
      },
    );
  };

  const getIsFieldVisible = (fieldSettings: IUIFieldSettings): boolean => {
    if (!fieldSettings) {
      return false;
    }

    return fieldSettings.show_with_payment_type &&
      fieldSettings.show_with_payment_type.length
      ? fieldSettings.show_with_payment_type.some(
          (paymentType) => paymentType === CheckoutStore.paymentMethod,
        )
      : true;
  };

  const goToConfirm = () => {
    if (!isLg) {
      document.getElementById('checkoutPageId').style.height =
        'calc(100vh - 100px)';
    }

    navigation.push('/menu/confirm');
  };

  const sendGA = () => {
    const orderItems = Object.values(CheckoutStore.currentOrder);

    GaService.instance.sendEvent({
      category: 'Ordering - Checkout',
      action: 'Success',
      label: `${Date.now()}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Tip',
      label:
        CheckoutStore.tipType === TipType.PERCENTAGE
          ? CheckoutStore.tipAmount
            ? `${CheckoutStore.tipAmount}%`
            : 'No tip'
          : 'Custom',
      value: round(
        CheckoutStore.tipType === TipType.PERCENTAGE
          ? (Number(summaryObject.subtotal) / 100) * CheckoutStore.tipAmount
          : CheckoutStore.tipAmount,
        2,
      ),
    });
    orderItems.forEach((item) => {
      GaService.instance.sendEvent({
        category: 'Ordering - Complete',
        action: 'Purchased Item',
        label: item.name,
        value: item.quantity,
      });
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Payment',
      label: CheckoutStore.paymentMethod,
      value: round(
        Number(summaryObject.total) +
          (CheckoutStore.tipType === TipType.PERCENTAGE
            ? (Number(summaryObject.subtotal) / 100) * CheckoutStore.tipAmount
            : CheckoutStore.tipAmount),
        2,
      ),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'User type',
      label: AuthStore.isGuest ? 'guest' : 'member',
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'User ID',
      label: `${AuthStore.userId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Business Name',
      label: LocationStore.selectedHotelObj.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Business ID',
      label: LocationStore.selectedHotel.toString(),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Location Name',
      label: LocationStore.selectedRestaurant.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Location ID',
      label: `${LocationStore.selectedRestaurantId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Experience Name',
      label: LocationStore.selectedExperience.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Experience ID',
      label: `${LocationStore.selectedExperienceId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Total items',
      label: 'Number of items',
      value: orderItems.reduce((acc, item) => acc + item.quantity, 0),
    });
    orderItems.forEach(
      (item) =>
        item.comment &&
        GaService.instance.sendEvent({
          category: 'Ordering - Complete',
          action: 'Items comments',
          label: item.name,
        }),
    );
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Guest count',
      label: CheckoutStore.numberOfDiners || '0',
    });
    CheckoutStore.comment &&
      GaService.instance.sendEvent({
        category: 'Ordering - Complete',
        action: 'Comments',
        label: CheckoutStore.comment,
      });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order time type',
      label:
        isSameDay(
          LocationStore.selectedDate,
          LocationStore.locationTimezoneName,
        ) &&
        (LocationStore.selectedDate === nearestTime ||
          isPast(LocationStore.selectedDate))
          ? 'ASAP'
          : 'Future',
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order date',
      label: dayjs(LocationStore.selectedDate).format('DD/MM/YYYY'),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order time',
      label: dayjs(LocationStore.selectedDate).format('hh:mm A'),
    });
  };

  const initCheckout = async () => {
    const accessToken = AuthStore.accessToken;
    const experienceId = LocationStore.selectedExperience.id;
    const businessId = LocationStore.selectedRestaurantId;
    const coupon: Coupon = CheckoutStore.getCoupon();

    const { cartId, summary, taxes, hash, isPendingCalculations } =
      await CheckoutStore.updateOrCreateCheckout({
        accessToken,
        businessId,
        experienceId,
        totalCalculation: true,
        couponCode:
          coupon &&
          getIsFieldVisible(LocationStore.uiCheckoutSettings['coupons'])
            ? coupon.code
            : undefined,
      });

    if (isMounted.current) {
      setTaxesArray(taxes || []);
      setSummaryObject(summary);
      setLocalPendingCalculations(isPendingCalculations);
      if (isPendingCalculations) {
        if (refFirebase.current) {
          refFirebase.current();
        }
        if (refFirebaseReject.current) {
          refFirebaseReject.current();
        }
        refFirebase.current = await startCartListening(
          cartId,
          hash,
          onFirebaseCartUpdate,
        );
        refFirebaseReject.current = await startCartListeningRejected(
          cartId,
          hash,
          onFirebaseCartRejected,
        );
      }
    }
  };

  const submitOrder = async (paymentMethod: string) => {
    try {
      setIsCheckoutFetching(true);
      if (isKioskMode) {
        dispatch(setKioskFlowFailed(false));
        navigation.push('loading');
      }

      const { is3DSecure } = await CheckoutStore.cartCheckout({
        accessToken: AuthStore.accessToken,
        businessId: LocationStore.selectedRestaurantId,
        checkoutAt: toUTCDateFormat(CheckoutStore.checkoutTime),
        experienceId: LocationStore.selectedExperience.id,
        isTipAvailable: LocationStore.isTipAvailable(paymentMethod),
        isCoupon:
          CheckoutStore.coupon &&
          getIsFieldVisible(LocationStore.uiCheckoutSettings['coupons']),
        kioskDeviceId,
        kioskEmailReceipt,
      });

      if (isKioskMode) {
        navigation.push('tapCard');
        try {
          await startOrderListening(+CheckoutStore.orderNumber);
          try {
            window.ReactNativeWebView.postMessage(
              KioskPostMessageEvent.RedirectToThankYou,
            );
          } catch (error) {
            console.error(KioskPostMessageEvent.Error);
          }
          CheckoutStore.removeAllOrderItems();
        } catch (err) {
          CheckoutStore.uuid = '';
          try {
            await initCheckout();
          } finally {
            throw err;
          }
        }
        setIsCheckoutFetching(false);
      }

      if (is3DSecure) {
        try {
          await startOrderListening(+CheckoutStore.orderNumber);
        } catch (err) {
          CheckoutStore.uuid = '';
          try {
            await initCheckout();
          } finally {
            throw err;
          }
        }
      }

      sendGA();
      goToConfirm();
    } catch (err) {
      if (err instanceof Error) {
        if (err.message !== 'CHALLENGE_DECLINED_BY_USER') {
          errorHandler.onError(err);
        }
        GaService.instance.sendEvent({
          category: 'Ordering - Checkout',
          action: 'Error',
          label: err.message,
        });
        setSelectedUser(null);
        setGuestOptions([]);
        if (isKioskMode) {
          if (err.message === 'Transaction status is CANCELED') {
            navigation.push('/menu/cancelTransaction');
            return;
          }
          dispatch(setKioskFlowFailed(true));
          navigation.push('wentWrong');
        }
      }
    } finally {
      setIsCheckoutFetching(false);
    }
  };

  const onSubmitFulfilled = async (
    {
      additionalComment,
      email,
      firstName,
      lastName,
      phone,
      numberOfGuest,
      paymentMethod,
      roomNumber,
      tableNumber,
      tip,
      customTip,
    }: IFormikValues,
    formikHelpers: FormikHelpers<IFormikValues>,
  ) => {
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.comments)) {
      CheckoutStore.setComment(additionalComment);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.email)) {
      CheckoutStore.setEmail(email);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.first_name)) {
      CheckoutStore.setFirstName(firstName);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.last_name)) {
      CheckoutStore.setLastName(lastName);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.guest_count)) {
      CheckoutStore.setNumberOfDiners(numberOfGuest);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.phone)) {
      CheckoutStore.setPhoneNumber(
        LocationStore.uiCheckoutSettings.phone ? phone : '',
      );
    }

    CheckoutStore.setRoomNumber(
      getIsFieldVisible(LocationStore.uiCheckoutSettings.room_number)
        ? roomNumber
        : '',
    );
    CheckoutStore.setTableNumber(
      getIsFieldVisible(LocationStore.uiCheckoutSettings.table_number)
        ? tableNumber
        : '',
    );
    CheckoutStore.setTip(tip as number);

    if (tip === TipStringValues.CUSTOM) {
      CheckoutStore.setTip(customTip, TipType.FIXED);
    }

    if (tip === TipStringValues.NO_TIP) {
      CheckoutStore.setTip(0);
    }

    const selectedHotelBID =
      LocationStore.selectedHotel ?? MyCheckApp.instance.getBusinessID();

    try {
      const isRoomCharge =
        paymentMethod === PaymentConstants.PaymentMethodsValue.ROOM;
      const hasValidation =
        LocationStore.selectedExperience.settings.payments.allowed.find(
          (e) => e.type === paymentMethod,
        )?.validation;

      if (
        LocationStore.selectedExperience.settings.is_room_validation ||
        (isRoomCharge && hasValidation)
      ) {
        if (!selectedUser) {
          const response = await CheckoutStore.validateRoom(
            isRoomCharge
              ? LocationStore.selectedRestaurantId
              : selectedHotelBID,
            AuthStore.accessToken,
            isRoomCharge,
          );

          if (
            response instanceof Error ||
            response.status === 'ERROR' ||
            response.guests.length === 0
          ) {
            if (response.message === 'NO_POST_ALLOWED') {
              errorHandler.onError({
                name: response.message,
                message: response.message,
              });
              return;
            }

            formikHelpers.setFieldError(
              'roomNumber',
              t('checkout.roomNumberDisclaimer'),
            );

            const page = document.getElementById('checkoutPageScroll');
            const elementOffsetTop =
              document.getElementById('roomNumber')?.offsetTop;

            (isLg ? page : window)?.scrollTo(
              0,
              elementOffsetTop - MODAL_HEADER_OFFSET,
            );

            return;
          }

          if (hasValidation) {
            if (response.guests.length > 1) {
              return setGuestOptions(response.guests);
            }

            CheckoutStore.setRoomToken(response.guests[0].token);
          }
        }
      }

      if (paymentMethod !== PaymentConstants.PaymentMethodsValue.WALLET) {
        await submitOrder(paymentMethod);
      } else {
        setPaymentsMethodOpen(true);
      }
    } catch (err) {
      if (err && err instanceof Error) {
        errorHandler.onError(err);
      }
    } finally {
      setIsCheckoutFetching(false);
    }
  };

  const onSubmit = async (
    props: IFormikValues,
    formikHelpers: FormikHelpers<IFormikValues>,
  ) => {
    setIsCheckoutFetching(true);

    const { didTimeChange, isAvailable } = await confirmSlot({
      isUpdateModalAvailable: true,
      isFutureModalAvailable: true,
      isClosedModalAvailable: true,
    });

    if (isAvailable === undefined) {
      await onSubmit(props, formikHelpers);
    }

    if (
      !isAvailable ||
      (didTimeChange &&
        !LocationStore.selectedExperience?.settings.is_asap_only)
    ) {
      setIsCheckoutFetching(false);
      return;
    }

    await menuTracker.checkIsSameMenu({
      callback: () => onSubmitFulfilled(props, formikHelpers),
    });
  };

  const onMount = async () => {
    if (!config.supportMembers && AuthStore.isGuest && !isLg) {
      AuthStore.logInGuest(LanguageStore.selectedValue, errorHandler.onError);
    }

    try {
      await initCheckout();
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err, handleErrorGoBack);
      }
    }
  };

  const onCouponVerify = (summary: CardSummary, taxes: Array<Tax>) => {
    setSummaryObject(summary);
    setTaxesArray(taxes || []);
  };

  useEffect(() => {
    if (!Object.keys(CheckoutStore.currentOrder).length) {
      return navigation.replace('/menu');
    }

    if (LocationStore.selectedExperience) {
      onMount();
    }

    return () => {
      isMounted.current = false;

      if (refFirebase.current) {
        refFirebase.current();
      }

      if (refFirebaseReject.current) {
        refFirebaseReject.current();
      }
    };
  }, [LocationStore.selectedExperience?.id]);

  useEffect(() => {
    if (!LocationStore.selectedExperience) {
      LocationStore.fetchExperience(LocationStore.selectedExperienceId);
    }

    if (!isLg) {
      window.scrollTo(0, 0);
    }

    return () => {
      if (refFirebase.current) {
        refFirebase.current();
      }
      if (refFirebaseReject.current) {
        refFirebaseReject.current();
      }

      //walletService.destroy();
    };
  }, []);

  return isKioskMode ? (
    <KioskCheckout
      isCheckoutFetching={isCheckoutFetching}
      localPendingCalculations={localPendingCalculations}
      submitOrder={submitOrder}
      summaryObject={summaryObject}
    />
  ) : (
    <StandardCheckout
      getIsFieldVisible={getIsFieldVisible}
      guestOptions={guestOptions}
      initCheckout={initCheckout}
      isCheckoutFetching={isCheckoutFetching}
      localPendingCalculations={localPendingCalculations}
      onCouponVerify={onCouponVerify}
      onSubmit={onSubmit}
      paymentsMethodOpen={paymentsMethodOpen}
      setGuestOptions={setGuestOptions}
      setPaymentsMethodOpen={setPaymentsMethodOpen}
      submitOrder={submitOrder}
      summaryObject={summaryObject}
      taxesArray={taxesArray}
    />
  );
};
