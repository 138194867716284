import React, { useCallback, useMemo } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useNavigation } from 'mycheck-core';

import { LangPicker } from '../../plugins/LanguagePlugin/components/LanguagePicker';
import { Background } from '../Background/Background';
import { HeaderProfile } from '../HeaderProfile/HeaderProfile';

import styles from './HeaderNavigation.module.scss';
import { getKioskStatus } from 'core/core/store/kiosk/slice';
import { useAppSelector } from 'core/core/store/store';

export interface HeaderNavigationProps {
  headerTitle?: string | JSX.Element;
  headerSubTitle?: string | JSX.Element;
  onLogoClick?: () => void;
  hideUserProfile?: boolean;
}

export const HeaderNavigation: React.FC<HeaderNavigationProps> = observer(
  ({ headerTitle, headerSubTitle, onLogoClick, hideUserProfile }) => {
    const isKioskMode = useAppSelector(getKioskStatus);
    const location = useLocation();

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const logo = get(_config, 'general.smallLogo', {});
      const blockHomePage = get(_config, 'settings.blockHomePage', true);
      const supportMembers = MyCheckApp.instance.getSupportMembersValue();

      return {
        logo,
        blockHomePage,
        supportMembers,
      };
    }, [location.search]);

    const showHeaderProfileMenu = config.supportMembers && !hideUserProfile;

    const navigation = useNavigation();
    const handleLogoClick = useCallback(() => {
      if (isKioskMode) {
        return;
      }
      if (onLogoClick) {
        onLogoClick();

        return;
      }

      if (!config.blockHomePage) navigation.push('/', true);
    }, [config.blockHomePage, window.location.search]);

    return (
      <Background className={styles.headerNavigation}>
        <div
          data-test-id="header-desktop-logo"
          className={styles.headerNavigationLogo}
          onClick={handleLogoClick}
        >
          <img
            alt="logo"
            className={styles.headerNavigationLogoImage}
            src={config.logo.url}
          />
        </div>
        <div className={styles.headerNavigationSubTitle}>{headerSubTitle}</div>
        <div
          data-test-id="header-title"
          className={styles.headerNavigationTitle}
        >
          {headerTitle}
        </div>
        <div className={styles.headerNavigationAction}>
          {showHeaderProfileMenu && <HeaderProfile />}
          <LangPicker />
        </div>
      </Background>
    );
  },
);

HeaderNavigation.displayName = 'HeaderNavigation';
