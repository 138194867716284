import React from 'react';
import { CustomButton, CustomIcon, PriceFormatter } from '@components';
import styles from '../../KioskCheckout.module.scss';
import { PaymentMethodsValue, useTranslation } from 'mycheck-core';
import { useOutletContext } from 'react-router-dom';
import { KioskOutletContext } from 'components/Kiosk/types';

export const KioskCheckoutBody: React.FC = () => {
  const { t } = useTranslation();
  const { backToMenu, obfuscatedEmailReceipt, submitOrder, total } =
    useOutletContext<
      Pick<
        KioskOutletContext,
        'backToMenu' | 'obfuscatedEmailReceipt' | 'submitOrder' | 'total'
      >
    >();

  const payByCard = async () => {
    await submitOrder(PaymentMethodsValue.EMV);
  };

  return (
    <>
      {obfuscatedEmailReceipt && (
        <h3 className={styles.emailTitle}>
          {t('kiosk.kioskCheckoutBody.emailTitle')}&nbsp;
          {obfuscatedEmailReceipt}
        </h3>
      )}
      <h1 className={styles.title}>
        {t('kiosk.kioskCheckoutBody.title')}&nbsp;
        <PriceFormatter value={Number(total)} withoutSpace />
      </h1>
      <h2 className={styles.subtitle}>
        {t('kiosk.kioskCheckoutBody.subtitle')}
      </h2>
      <CustomIcon
        name="bigArrowDown"
        style={{ color: '#C6C6C6', width: '80px', margin: '72px 0' }}
      />
      <CustomButton
        className={styles.buttonAction}
        text={t('kiosk.kioskCheckoutBody.primaryButton')}
        testId="kiosk-checkout-primary-button"
        handleButtonClick={payByCard}
      />
      <CustomButton
        className={styles.button}
        text={t('kiosk.kioskCheckoutBody.secondaryButton')}
        testId="kiosk-checkout-secondary-button"
        isBorderColor
        handleButtonClick={backToMenu}
      />
    </>
  );
};
