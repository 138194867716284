import React, { useMemo, useEffect, useState, type CSSProperties } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import get from 'lodash/get';
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as yup from 'yup';
import { StringSchema } from 'yup';

import {
  getDateLabel,
  MyCheckApp,
  PaymentConstants,
  toHourFormatDisplay,
  useAuthStore,
  useCheckoutStore,
  useLanguageStore,
  useLocationStore,
  useModalNavigator,
  useNavigation,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import {
  Background,
  CustomIcon,
  Header,
  Loader,
  Overlay,
  TranslateText,
} from '@components';

import { CheckoutForm } from '../CheckoutForm/CheckoutForm';
import { GuestSelectionPopup } from '../GuestSelection/GuestSelectionPopup';
import { PaymentsMethod } from '../PaymentsMethod/PaymentsMethod';

import styles from './StandardCheckout.module.scss';
import {
  IUICheckoutSettings,
  IUIFieldSettings,
} from 'plugins/LocationPlugin/types/LocationTypes';
import {
  CardSummary,
  RoomUser,
  Tax,
} from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { IFormikValues } from 'plugins/CheckoutPlugin/types/IFormikValues';

interface StandardCheckoutProps {
  getIsFieldVisible: (fieldSettings: IUIFieldSettings) => boolean;
  guestOptions: RoomUser[];
  initCheckout: () => Promise<void>;
  isCheckoutFetching: boolean;
  localPendingCalculations: boolean;
  onCouponVerify: (summary: CardSummary, taxes: Tax[]) => void;
  onSubmit: (
    props: IFormikValues,
    formikHelpers: FormikHelpers<IFormikValues>,
  ) => Promise<void>;
  paymentsMethodOpen: boolean;
  setGuestOptions: (guestOptions: RoomUser[]) => void;
  setPaymentsMethodOpen: (paymentsMethodOpen: boolean) => void;
  submitOrder: (paymentMethod: string) => Promise<void>;
  summaryObject: Partial<CardSummary>;
  taxesArray: Tax[];
}

export const StandardCheckout: React.FC<StandardCheckoutProps> = ({
  getIsFieldVisible,
  guestOptions,
  initCheckout,
  isCheckoutFetching,
  localPendingCalculations,
  onCouponVerify,
  onSubmit,
  paymentsMethodOpen,
  setPaymentsMethodOpen,
  setGuestOptions,
  submitOrder,
  summaryObject,
  taxesArray,
}) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const navigation = useNavigation();

  const { openRegisterPage } = useModalNavigator();
  const navigationType = useNavigationType();

  const [loading, setLoading] = useState(false);
  const [enableValidation, setEnableValidation] = useState(false);

  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const registerTextStyle = get(_config, 'general.text', {});
    const linkStyle = get(_config, 'general.link', {});
    const primary = get(_config, 'palette.primary', {});

    return {
      registerTextStyle,
      linkStyle,
      primary,
      supportMembers,
    };
  }, [location.search]);

  const handleSignInClick = () => openRegisterPage();
  const onCloseGuestSelection = () => setGuestOptions([]);

  const getValidationSettingsForField = (
    property: keyof IUICheckoutSettings,
  ) => {
    const fieldSettings: IUIFieldSettings | null = LocationStore
      .uiCheckoutSettings[property] as IUIFieldSettings;
    if (!fieldSettings) {
      return null;
    }

    let validationSchema =
      fieldSettings.type === 'number' ? yup.number() : yup.string().trim();

    if (property === 'guest_count') {
      validationSchema = yup.string();
    }

    if (property === 'table_number') {
      validationSchema = yup
        .string()
        .test('minValue', 'Must be greater than 0', (val) => {
          if (!val || isNaN(Number(val))) {
            return true;
          }
          return Number(val) > 0;
        });
    }

    if (fieldSettings.required && getIsFieldVisible(fieldSettings)) {
      validationSchema = validationSchema.required();
    }

    if (fieldSettings.type === 'email') {
      validationSchema = (validationSchema as StringSchema).email(
        'profile.emailValidationError.invalidEmail',
      );
    }

    if (fieldSettings.min) {
      validationSchema = validationSchema.min(fieldSettings.min);
    }

    if (fieldSettings.max) {
      validationSchema = validationSchema.max(fieldSettings.max);
    }

    if (property === 'phone') {
      validationSchema = (validationSchema as yup.NumberSchema).test({
        name: 'phoneNumber',
        message: 'Error',
        // DO NOT CHANGE to arrow function, arrow function binds context so below code will NOT work
        test: function (value) {
          if (fieldSettings.required && getIsFieldVisible(fieldSettings)) {
            return isValidPhoneNumber('+' + value, this.parent.country);
          }

          if (this.parent.dialCode === value || !value) {
            return true;
          }

          return isValidPhoneNumber('+' + value, this.parent.country);
        },
      });

      return validationSchema;
    }

    if (property === 'room_number') {
      validationSchema = (validationSchema as StringSchema).test({
        name: 'room_number',
        message: 'Error',
        // DO NOT CHANGE to arrow function, arrow function binds context so below code will NOT work
        test: function (value: string) {
          if (
            (fieldSettings.required && getIsFieldVisible(fieldSettings)) ||
            value?.length > 0
          ) {
            return /\d/.test(value);
          }

          return true;
        },
      });

      return validationSchema;
    }
    return validationSchema;
  };
  // TODO sort out this validation schema and remove strange util function
  const getValidationSchema = () =>
    yup.object().shape({
      firstName: getValidationSettingsForField('first_name'),
      lastName: getValidationSettingsForField('last_name'),
      phone: getValidationSettingsForField('phone'),
      email: getValidationSettingsForField('email'),
      roomNumber: getValidationSettingsForField('room_number'),
      tableNumber: getValidationSettingsForField('table_number'),
      numberOfGuest: getValidationSettingsForField('guest_count'),
      additionalComment: getValidationSettingsForField('comments'),
      termsAndConditions: yup.boolean().oneOf([true]).required(),
      tip: LocationStore.uiCheckoutSettings.tip
        ? yup.string().required()
        : null,
    });

  const onSubmitGuestSelection = async (roomToken: string) => {
    setGuestOptions([]);
    CheckoutStore.setRoomToken(roomToken);
    await submitOrder(CheckoutStore.paymentMethod);
  };

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      setLoading(true);
    }
  }, [history]);

  const selectedRestaurantCountryCode =
    LocationStore.selectedRestaurant.place?.data.address_components
      .find((comp) => comp.types.includes('country'))
      ?.short_name.toLowerCase();

  const headerMobileStyle: CSSProperties = !isLg
    ? {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 3,
      }
    : {
        backgroundColor: config.primary,
        color: '#ffffff',
        width: 480,
      };

  const renderBody = () => {
    return (
      <div className={styles.checkoutPage} id="checkoutPageId">
        <Header
          headerTitle={t('checkout.checkoutTitle')}
          color="#ffffff"
          rootStyle={headerMobileStyle}
          handleBackClick={() =>
            isLg ? navigation.push('/menu') : navigation.push('/menu/order')
          }
          enforceSmall
        />

        <Background
          className={styles.checkoutPageScroll}
          id="checkoutPageScroll"
        >
          {config.supportMembers && !AuthStore.isLoggedIn && (
            <div
              className={styles.checkoutPageRegisterWrap}
              style={config.registerTextStyle}
            >
              <div className={styles.checkoutPageRegisterWrapTitle}>
                {t('checkout.alreadyRegisteredQuestion')}
              </div>
              <div
                data-test-id="checkout-sign-in"
                onClick={handleSignInClick}
                className={styles.checkoutPageRegisterWrapRegister}
                style={config.linkStyle}
              >
                {t('checkout.signInLink')}
              </div>
            </div>
          )}

          <div className={styles.checkoutPageInfoWrap}>
            <div className={styles.checkoutPageInfoWrapExperiance}>
              {t(`experience.${LocationStore.selectedExperienceType}`)}
            </div>
            <div className={styles.checkoutPageInfoWrapRestaurant}>
              {LanguageStore.getBusinessTranslation(
                LocationStore.selectedRestaurant.id,
                'name',
                LocationStore.selectedRestaurant.name,
              )}
            </div>
            {(!LocationStore.selectedExperience?.settings.is_asap_only ||
              LocationStore.selectedExperience?.settings.display_order_time) &&
              CheckoutStore.checkoutTime && (
                <div className={styles.checkoutPageInfoWrapTime}>
                  <CustomIcon
                    name="time"
                    className={styles.checkoutPageInfoWrapTimeIcon}
                  />
                  <TranslateText
                    tKey="checkout.deliverTo"
                    values={{
                      at: toHourFormatDisplay(
                        CheckoutStore.checkoutTime,
                        LocationStore.locationTimezoneName,
                        LocationStore.localTimeFormat,
                      ),
                      when: getDateLabel(
                        CheckoutStore.checkoutTime,
                        LocationStore.locationTimezoneName,
                        LocationStore.localDateFormat,
                      ),
                    }}
                  />
                </div>
              )}
          </div>
          {!!LocationStore.selectedExperience ? (
            <Formik
              initialValues={{
                additionalComment: '',
                email: AuthStore.email,
                firstName: AuthStore.userFirstName,
                lastName: AuthStore.userLastName,
                phone: AuthStore.phone,
                numberOfGuest: '',
                paymentMethod:
                  CheckoutStore.paymentMethod ||
                  LocationStore.selectedExperience?.settings.payments?.default,
                deliveryOption: LocationStore.deliveryOption,
                roomNumber: CheckoutStore.roomNumber,
                tableNumber: CheckoutStore.tableNumber,
                termsAndConditions: false,
                tip: LocationStore.isTipAvailable(
                  CheckoutStore.paymentMethod ||
                    LocationStore.selectedExperience?.settings.payments
                      ?.default,
                )
                  ? LocationStore.uiCheckoutSettings?.tip?.selected
                  : 0,
                country: selectedRestaurantCountryCode,
              }}
              validationSchema={getValidationSchema}
              onSubmit={onSubmit}
              validateOnChange={enableValidation}
              validateOnBlur={enableValidation}
            >
              {(formikProps) => (
                <CheckoutForm
                  formikProps={formikProps}
                  summary={summaryObject}
                  taxes={taxesArray}
                  isPendingCalculations={localPendingCalculations}
                  setEnableValidation={setEnableValidation}
                  validationSchema={getValidationSchema()}
                  isCheckoutFetching={isCheckoutFetching}
                  onCouponVerify={onCouponVerify}
                />
              )}
            </Formik>
          ) : (
            <Loader withOverlay={false} />
          )}
        </Background>
        {!!guestOptions.length && (
          <GuestSelectionPopup
            options={guestOptions}
            onSubmit={onSubmitGuestSelection}
            onClose={onCloseGuestSelection}
          />
        )}
      </div>
    );
  };

  if (isLg) {
    return (
      <>
        <Overlay width={480} height={724} testId="checkout-page">
          {!loading && renderBody()}
          {paymentsMethodOpen && (
            <PaymentsMethod
              summary={summaryObject}
              handleOpen={() => {
                setPaymentsMethodOpen(!paymentsMethodOpen);
              }}
              type={
                CheckoutStore.paymentMethod ===
                PaymentConstants.PaymentMethodsValue.WALLET
                  ? 'WALLET'
                  : 'CHOOSE'
              }
              initCheckout={initCheckout}
            />
          )}
        </Overlay>
        {(isCheckoutFetching || loading) && <Loader />}
      </>
    );
  }

  return (
    <>
      {!loading && renderBody()}
      {paymentsMethodOpen && (
        <PaymentsMethod
          summary={summaryObject}
          handleOpen={() => {
            setPaymentsMethodOpen(!paymentsMethodOpen);
          }}
          type={
            CheckoutStore.paymentMethod ===
            PaymentConstants.PaymentMethodsValue.WALLET
              ? 'WALLET'
              : 'CHOOSE'
          }
        />
      )}
      {(isCheckoutFetching || loading) && (
        <Loader
          label={t('checkout.submittingOrder')}
          labelStyle={{
            color: '#ffffff',
            fontSize: 16,
          }}
        />
      )}
    </>
  );
};
