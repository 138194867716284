import React, { useMemo, type CSSProperties } from 'react';

import { css } from 'glamor';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { MyCheckApp } from 'mycheck-core';

import { getTimestamp } from '../../core/core/service/TimestampService';

import { IconSvg, srcPlace, stylePlace } from './helpers';

type Props = {
  name: IconSvg;
  className?: string;
  style?: CSSProperties & { radioBorderColor?: string };
  alt?: string;
  customStylePath?: string;
};

const ICONS_TIMESTAMP = 'ICONS_TIMESTAMP';

const CachedTimestamp =
  JSON.parse(window.localStorage.getItem(ICONS_TIMESTAMP)) || {};

const handleTimestamp = (propertyName: string) => {
  if (CachedTimestamp[propertyName]) {
    return CachedTimestamp[propertyName];
  }

  CachedTimestamp[propertyName] = getTimestamp();
  window.localStorage.setItem(ICONS_TIMESTAMP, JSON.stringify(CachedTimestamp));
  return CachedTimestamp[propertyName];
};

export const CustomIcon: React.FC<Props> = ({
  name,
  className,
  alt = '',
  style,
  customStylePath,
}) => {
  const location = useLocation();

  const configStyles = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const isRadio = name === 'radioEmpty' || name === 'radioFilled';
    const iconStyle = get(_config, customStylePath || stylePlace(name), {});
    const iconSrc = get(_config, `general.icons.${name}`, null);
    const iconFill = {
      fill: `${style?.color || iconStyle.color} !important`,
    };
    const radioBorderFill = {
      fill: `${style?.radioBorderColor || iconStyle.color} !important`,
    };
    const iconCss = css({
      height: 'fit-content',
      '& > div': {
        display: 'flex',
      },
      ' svg': {
        ...iconStyle,
        ...(style?.width && { width: style.width }),
        height: '100%',
        pointerEvents: 'none',
      },
      ' *': !isRadio && iconFill,
      ' path': isRadio && radioBorderFill,
      ' circle': isRadio && iconFill,
    });
    return {
      iconSrc,
      iconCss,
    };
  }, [location.search, style, name]);

  const timestamp = handleTimestamp(name);
  if (!configStyles.iconSrc && !srcPlace(name)) {
    return null;
  }

  return (
    <ReactSVG
      src={
        configStyles.iconSrc
          ? `${configStyles.iconSrc}?timestamp=${timestamp}`
          : srcPlace(name)
      }
      alt={alt}
      {...configStyles.iconCss}
      className={className}
      style={style}
    />
  );
};
