export type IconSvg =
  | 'languagePicker'
  | 'profile'
  | 'editInput'
  | 'nextInput'
  | 'arrowDown'
  | 'search'
  | 'step1'
  | 'step2'
  | 'step3'
  | 'arrow'
  | 'editProfile'
  | 'terms'
  | 'privacy'
  | 'history'
  | 'wallet'
  | 'logout'
  | 'hotel'
  | 'time'
  | 'pencil'
  | 'x'
  | 'i'
  | 'v'
  | 'back'
  | 'backIconMobile'
  | 'globe'
  | 'emptyCheckbox'
  | 'filledCheckbox'
  | 'radioFilled'
  | 'radioEmpty'
  | 'noConnection'
  | 'rotate'
  | 'menu'
  | 'loader'
  | 'emptyCart'
  | 'fullCart'
  | 'cartWithLine'
  | 'poweredBy'
  | 'minus'
  | 'plus'
  | 'forward'
  | 'checkbox'
  | 'checkboxV'
  | 'arrowRight'
  | 'arrowLeft'
  | 'error'
  | 'info'
  | 'backIcon'
  | 'location'
  | 'phone'
  | 'coupons.logo'
  | 'coupons.applied'
  | 'coupons.unhappy'
  | 'userProfile'
  | 'bigArrowDown'
  | 'disable'
  | 'warning';

const stylePlace = (name: IconSvg) => {
  switch (name) {
    case 'languagePicker':
    case 'arrowDown':
    case 'menu':
    case 'search':
    case 'poweredBy':
      return `home.icons.${name}`;

    case 'x':
      return `general.search.${name}`;

    case 'v':
      return `home.languagePicker.icons.${name}`;

    case 'editProfile':
    case 'terms':
    case 'privacy':
    case 'logout':
    case 'history':
      return `profile.icons.${name}`;

    case 'i':
    case 'info':
    case 'emptyCart':
    case 'fullCart':
    case 'step1':
    case 'step2':
    case 'step3':
    case 'arrow':
      return `menu.icons.${name}`;

    case 'pencil':
    case 'hotel':
    case 'time':
      return `restaurants.icons.${name}`;

    case 'back':
      return 'general.backIcon';

    case 'loader':
    case 'error':
      return `general.${name}`;

    case 'rotate':
      return 'landscape.icon';

    default:
      return '';
  }
};

const srcPlace = (name: IconSvg) => {
  const sourcePath =
    'https://static-content.mycheckapp.com/pickupv2/updated_assets/';
  switch (name) {
    case 'bigArrowDown':
      return `${sourcePath}bigArrowDown.svg`;
    case 'disable':
      return `${sourcePath}disable.svg`;
    case 'info':
      return `${sourcePath}info.svg`;
    case 'warning':
      return `${sourcePath}warning.svg`;
    default:
      return '';
  }
};

export { srcPlace, stylePlace };
