import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectors } from './selectors';
import { obfuscateEmailAddress } from 'core/core/helpers/obfuscateEmailAddress';

export type KioskState = {
  deviceId: string;
  isEnabled: boolean;
  isFlowFailed: boolean;
  emailReceipt: string | null;
  obfuscatedEmailReceipt: string | null;
};

const initialState: KioskState = {
  deviceId: '',
  isEnabled: false,
  isFlowFailed: false,
  emailReceipt: null,
  obfuscatedEmailReceipt: null,
};

const kioskSlice = createSlice({
  name: 'kiosk',
  initialState: initialState,
  selectors,
  reducers: {
    setKioskDeviceId: (state, { payload: deviceId }: PayloadAction<string>) => {
      state.deviceId = deviceId;
    },
    setKioskEnabled: (state) => {
      state.isEnabled = true;
    },
    setKioskFlowFailed: (
      state,
      { payload: failed }: PayloadAction<boolean>,
    ) => {
      state.isFlowFailed = failed;
    },
    setKioskEmailReceipt: (
      state,
      { payload: email }: PayloadAction<string>,
    ) => {
      state.emailReceipt = email || null;
      state.obfuscatedEmailReceipt = email
        ? obfuscateEmailAddress(email)
        : null;
    },
  },
});

export const {
  setKioskDeviceId,
  setKioskEmailReceipt,
  setKioskEnabled,
  setKioskFlowFailed,
} = kioskSlice.actions;
export default kioskSlice.reducer;
export const {
  getKioskDeviceId,
  getKioskStatus,
  getKioskFailedFlow,
  getKioskEmailReceipt,
  getKioskObfuscatedEmailReceipt,
} = kioskSlice.selectors;
