import { attachFirebase } from './ExternalJavascript';

const CART_TIMESTAMP = 'CART_TIMESTAMP';
const CART_TIMESTAMP_REJECTED = 'CART_TIMESTAMP_REJECTED';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const initializeApp = async () => {
  if (!window.firebase?.apps?.length) {
    await attachFirebase();
    window.firebase.initializeApp(firebaseConfig);
  }
};

export const startCartListening = async (
  cartId: string,
  hash: string,
  callback: (value: Record<string, unknown>) => void,
) => {
  await initializeApp();

  const database = window.firebase.database();
  const cartRef = database
    .ref(`restaurants/carts/calculated`)
    .orderByChild('cart_id')
    .equalTo(cartId);

  const onValueChange = (snapshot) => {
    const valueFromFirebase = snapshot.val();
    const latestTimestamp = Number(window.localStorage.getItem(CART_TIMESTAMP));
    if (
      valueFromFirebase &&
      valueFromFirebase.hash === hash &&
      valueFromFirebase.timestamp > latestTimestamp
    ) {
      window.localStorage.setItem(CART_TIMESTAMP, valueFromFirebase.timestamp);
      callback(valueFromFirebase);
    }
  };

  cartRef.on('child_added', onValueChange);

  return () => cartRef.off('child_added', onValueChange);
};

export const startCartListeningRejected = async (
  cartId: string,
  hash: string,
  callback: (value: Record<string, unknown>) => void,
) => {
  await initializeApp();

  const database = window.firebase.database();
  const cartRef = database
    .ref(`restaurants/carts/rejected`)
    .orderByChild('cart_id')
    .equalTo(cartId);

  const onValueChange = (snapshot) => {
    const valueFromFirebase = snapshot.val();
    const latestTimestampRejected = Number(
      window.localStorage.getItem(CART_TIMESTAMP_REJECTED),
    );
    if (
      valueFromFirebase &&
      valueFromFirebase.hash === hash &&
      valueFromFirebase.timestamp > latestTimestampRejected
    ) {
      window.localStorage.setItem(
        CART_TIMESTAMP_REJECTED,
        valueFromFirebase.timestamp,
      );
      callback(valueFromFirebase);
    }
  };

  cartRef.on('child_added', onValueChange);

  return () => cartRef.off('child_added', onValueChange);
};

const errorMessages = {
  4236: 'after3DsComplete',
  4700: 'CART_CHECKOUT_FAILED',
  4244: 'CHALLENGE_DECLINED_BY_USER',
  6207: 'Transaction status is CANCELED',
};

export const startOrderListening = async (orderId: number) => {
  await initializeApp();

  const database = window.firebase.database();
  const orderRef = database.ref(`restaurants/orders/${orderId}/transactions`);

  return new Promise<void>((res, rej) => {
    const onValueChange = (snapshot) => {
      const valueFromFirebase = snapshot.val();
      if (!valueFromFirebase) {
        return;
      }
      orderRef.off('value', onValueChange);
      const value = Object.values(valueFromFirebase)[0] as
        | { eventHandlingStatus: 'SUCCESS' }
        | {
            eventHandlingStatus: 'FAILURE';
            exceptionInfo: { message?: string; code?: number };
          };
      if (value.eventHandlingStatus === 'SUCCESS') {
        res();
        return;
      }
      rej(
        new Error(
          errorMessages[value.exceptionInfo.code] ??
            value.exceptionInfo.message,
        ),
      );
    };

    orderRef.on('value', onValueChange);
  });
};
