import React, { PropsWithChildren } from 'react';

import { Background, CustomIcon, KioskButton, Overlay } from '@components';

import styles from './KioskModal.module.scss';

interface KioskModalProps extends PropsWithChildren {
  testId: string;
  primaryButtonClick: () => void;
  secondaryButtonClick: () => void;
  title: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  headerTitle?: string;
  withCenterTitle?: boolean;
  onClose?: () => void;
}

export const KioskModal: React.FC<KioskModalProps> = ({
  testId,
  onClose,
  primaryButtonClick,
  secondaryButtonClick,
  children,
  title,
  primaryButtonText,
  secondaryButtonText,
  headerTitle,
  withCenterTitle = false,
}) => (
  <Overlay
    overlayStyle={{ zIndex: 10 }}
    className={styles.root}
    testId={testId}
    isPopup
    isKioskMode
    onClickOutside={onClose}
  >
    <Background>
      {headerTitle && (
        <div className={styles.headerContainer}>
          <span className={styles.headerTitle}>{headerTitle}</span>
          <div onClick={onClose}>
            <CustomIcon
              name="x"
              style={{ width: '16px', height: '16px', cursor: 'pointer' }}
            />
          </div>
        </div>
      )}
      <div
        className={styles.title}
        style={{ ...(withCenterTitle && { textAlign: 'center' }) }}
      >
        {title}
      </div>
      <div className={styles.container}>{children}</div>
      <KioskButton
        className={styles.buttonAction}
        testId="kiosk-modal-primary-button"
        onClick={primaryButtonClick}
      >
        {primaryButtonText}
      </KioskButton>
      <KioskButton
        testId="kiosk-modal-secondary-button"
        onClick={secondaryButtonClick}
        withTransparentBG
      >
        {secondaryButtonText}
      </KioskButton>
    </Background>
  </Overlay>
);
