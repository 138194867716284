import { useEffect, useRef, useState } from 'react';

type EventsType = 'touchstart' | 'mousedown' | 'keydown';

interface UseIdleTimerProps {
  enable: boolean;
  timeout: number;
  onTimeout: () => void;
  interval?: number;
  events?: EventsType[];
  resetTimerOnUserActivity?: boolean;
}

const useIdleTimer = ({
  enable,
  timeout,
  onTimeout,
  interval = 1000,
  events = ['touchstart', 'mousedown', 'keydown'],
  resetTimerOnUserActivity = true,
}: UseIdleTimerProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(timeout);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const elapsedTimeRef = useRef(0);

  const clearCurrenInterval = () => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const reset = () => {
    clearCurrenInterval();
    elapsedTimeRef.current = 0;
    setRemainingTime(timeout);
  };

  const start = () => {
    if (intervalId.current === null) {
      intervalId.current = setInterval(() => {
        elapsedTimeRef.current += interval;
        setRemainingTime(Math.max(0, timeout - elapsedTimeRef.current));
        if (elapsedTimeRef.current >= timeout) {
          onTimeout();
          reset();
        }
      }, interval);
    }
  };

  useEffect(() => {
    if (resetTimerOnUserActivity && intervalId.current) {
      const handleUserActivity = () => {
        elapsedTimeRef.current = 0;
      };

      events.forEach((event) => {
        document.addEventListener(event, handleUserActivity);
      });

      return () => {
        events.forEach((event) => {
          document.removeEventListener(event, handleUserActivity);
        });
      };
    }
  }, [events, resetTimerOnUserActivity]);

  useEffect(() => {
    if (enable) {
      start();
    }
    return () => {
      clearCurrenInterval();
    };
  }, [enable]);

  return {
    start,
    reset,
    remainingTime,
    elapsedTime: elapsedTimeRef.current,
  };
};

export default useIdleTimer;
