import React, { useMemo, type CSSProperties } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment-timezone';

import {
  getDateDays,
  getSearchState,
  isBetween,
  isPast,
  isWithinSixtyMinutes,
  useActiveScroll,
  useLanguageStore,
  useLocationStore,
  useNearestTime,
  useScrollPosition,
  useWindowSize,
} from 'mycheck-core';

import { Background } from '@components';

import { Modal } from '../../../../components/Modal/Modal';
import { ExperienceDropdown } from '../ExperienceDropdown/ExperienceDropdown';
import { MoreInfo } from '../MoreInfo/MoreInfo';

import styles from './LocationContent.module.scss';
import { LocationContentExperience } from './LocationContentExperience';

interface Props {
  style?: CSSProperties;
}

export const LocationContent: React.FC<Props> = observer((props) => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const { nearestTime } = useNearestTime();
  const { isLg } = useWindowSize();
  const activeScroll = useActiveScroll();
  const scrollPosition = useScrollPosition();

  const sorter = [
    'orderNow',
    'around',
    'nextAvailableLabel',
    'viewMenu',
    'notAvailable',
  ];

  const getSorterType = (restaurant) => {
    const {
      isViewMenuOnly,
      openHours,
      isAsapOnly,
      isOpen,
      displayOrderTime,
      isWithinOneHourSelectedDate,
      noNextAvailable,
      nearestIsWithinSixtyMinutes,
    } = restaurant._sort;

    if (isViewMenuOnly) {
      return 'viewMenu';
    }

    if (!openHours || (Array.isArray(openHours) && !openHours.length)) {
      return 'notAvailable';
    }

    if (isAsapOnly) {
      if (
        LocationStore.selectedDate === nearestTime ||
        isPast(LocationStore.selectedDate)
      ) {
        if (isOpen && isWithinOneHourSelectedDate) {
          if (displayOrderTime) {
            return 'around';
          }
          return 'orderNow';
        }

        return 'notAvailable';
      }

      return 'notAvailable';
    }

    if (noNextAvailable) {
      return 'notAvailable';
    }

    if (isOpen) {
      if (nearestIsWithinSixtyMinutes) return 'around';
    }

    return 'nextAvailableLabel';
  };

  const sortLocation = (a, b) => {
    const nameA = LanguageStore.getBusinessTranslation(a.id, 'name', a.name);
    const nameB = LanguageStore.getBusinessTranslation(b.id, 'name', b.name);

    const sorterTypeA = getSorterType(a);
    const sorterTypeB = getSorterType(b);

    // if same type sort by name alphabetical
    if (sorterTypeA === sorterTypeB) {
      if (sorterTypeA === 'nextAvailableLabel') {
        return moment(a._sort.nextAvailable).isBefore(b._sort.nextAvailable)
          ? -1
          : 1;
      }

      if (sorterTypeA === 'around') {
        if (a._sort.minimumPickupTime === b._sort.minimumPickupTime) {
          return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
        }

        return a._sort.minimumPickupTime < b._sort.minimumPickupTime ? -1 : 1;
      }

      return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
    }

    // sort by type
    return sorter.indexOf(sorterTypeA) - sorter.indexOf(sorterTypeB);
  };

  const restaurants = useMemo(() => {
    if (!LocationStore.restaurantList.length) return [];

    let restaurantList = LocationStore.restaurantList
      .filter((restaurant) =>
        restaurant.experiences.find(
          (experience) =>
            experience.type === LocationStore.selectedExperienceType,
        ),
      )
      .map((restaurant) => {
        const selectedExperience = restaurant.experiences.find(
          (experience) =>
            experience.type === LocationStore.selectedExperienceType,
        );
        const nextAvailable = selectedExperience.settings.slots.next_available;
        const noNextAvailable = nextAvailable === null;
        const displayOrderTime = selectedExperience.settings.display_order_time;
        const nextAvailableDiffFromNow = LocationStore.locationTimezoneName
          ? moment(nextAvailable)
              .tz(LocationStore.locationTimezoneName)
              .diff(moment().tz(LocationStore.locationTimezoneName), 'minutes')
          : 1;
        const minimumPickupTime =
          nextAvailableDiffFromNow === 0 ? 1 : nextAvailableDiffFromNow;
        const isAsapOnly = selectedExperience.settings.is_asap_only;
        const isViewMenuOnly =
          !selectedExperience.settings.checkout?.is_active ?? true;
        const openHours = selectedExperience.settings.open_hours;
        const isWithinOneHourSelectedDate = isWithinSixtyMinutes(
          nextAvailable,
          LocationStore.selectedDate,
        );
        const nearestIsWithinSixtyMinutes = isWithinSixtyMinutes(
          LocationStore.selectedDate,
          nearestTime,
        );
        const openDays =
          typeof LocationStore.locationTimezoneName !== 'undefined'
            ? getDateDays(
                Object.keys(openHours),
                LocationStore.locationTimezoneName,
              )
            : null;
        const isOpen = openHours[
          openDays?.today.format('ddd').toLowerCase()
        ]?.some((element) =>
          isBetween(
            element.from,
            element.to,
            LocationStore.locationTimezoneName,
          ),
        );

        return {
          ...restaurant,
          _sort: {
            selectedExperience,
            nextAvailable,
            noNextAvailable,
            displayOrderTime,
            minimumPickupTime,
            isAsapOnly,
            openHours,
            isViewMenuOnly,
            isWithinOneHourSelectedDate,
            nearestIsWithinSixtyMinutes,
            openDays,
            isOpen,
          },
        };
      })
      .sort(sortLocation);
    const searchState = getSearchState();
    if (searchState.urlrestaurant) {
      const restSelected = restaurantList.filter(
        (r) => r.id === searchState.urlrestaurant,
      );
      if (restSelected.length) {
        restaurantList = restaurantList.filter(
          (r) => r.id !== searchState.urlrestaurant,
        );
        restaurantList.unshift(restSelected[0]);
      }
    }

    return restaurantList;
  }, [
    LocationStore.restaurantList,
    nearestTime,
    LocationStore.selectedExperienceType,
  ]);

  const handleMoreInfo = () => {
    LocationStore.setMoreInfo(null);
  };

  const restaurant = LocationStore.restaurantList.find(
    (restaurantObj) => restaurantObj.id === LocationStore.moreInfo,
  );

  const restaurantName = restaurant
    ? LanguageStore.getBusinessTranslation(
        restaurant.id,
        'name',
        restaurant.name,
      )
    : '';

  const isMoreInfoOpen = isLg && LocationStore.moreInfo;

  return (
    <Background
      secondary
      className={styles.locationContent}
      id="locationContent"
      style={props.style}
    >
      <div className={styles.locationOverflow}>
        {!isLg && (
          <div className={styles.experienceDropdown}>
            <ExperienceDropdown
              fixedToTop={
                activeScroll &&
                !isLg &&
                !LocationStore.isNative &&
                !LocationStore.isIframe &&
                scrollPosition > 41
              }
            />
          </div>
        )}
        <div className={styles.restaurants}>
          {restaurants.map((restaurantObj) => (
            <LocationContentExperience
              restaurant={restaurantObj}
              key={`${restaurantObj.id}`}
            />
          ))}
        </div>
      </div>

      {isMoreInfoOpen && (
        <Modal
          onClose={handleMoreInfo}
          title={restaurantName}
          open={isMoreInfoOpen}
          testId="more-info-modal"
        >
          <MoreInfo restaurantId={LocationStore.moreInfo} />
        </Modal>
      )}
    </Background>
  );
});

LocationContent.displayName = 'LocationContent';
