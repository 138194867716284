import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { Formik } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import * as yup from 'yup';

import {
  MyCheckApp,
  Trans,
  getYear,
  useAuthStore,
  useErrorTrackerHandler,
  useLanguageStore,
  useModalNavigator,
  useTranslation,
  useWindowSize,
  emailValidationForm,
} from 'mycheck-core';

import {
  Background,
  CustomInput,
  CustomPhoneInput,
  DatePickerComponent,
  Header,
  Overlay,
  PopUp,
  PrimaryButton,
  RadioButtonGroup,
  SanitizedHtml,
  Text,
} from '@components';

import styles from './EditProfilePage.module.scss';

export const EditProfilePage: React.FC = observer(() => {
  const AuthStore = useAuthStore();
  const LanguageStore = useLanguageStore();
  const errorHandler = useErrorTrackerHandler();
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const { closeEditProfilePage } = useModalNavigator();
  const maxYear = getYear(new Date()) + 1;
  const minYear = 1920;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const linkStyle = get(_config, 'editProfile.link', {});
    const marketingOptIn = get(_config, 'settings.marketingOptInStatement', {});
    const marketingOptOut = get(
      _config,
      'settings.marketingOptOutStatement',
      {},
    );
    const accountDeletionInstruction = get(
      _config,
      'settings.accountDeletionInstruction',
      {},
    );

    return {
      linkStyle,
      accountDeletionInstruction,
      marketingOptIn,
      marketingOptOut,
    };
  }, []);

  const onShowDeleteModal = useCallback(() => setShowDeleteModal(true), []);
  const onHideDeleteModal = useCallback(() => setShowDeleteModal(false), []);
  const handleBackClick = useCallback(() => closeEditProfilePage(), []);
  const getValidateSchema = useCallback(
    () =>
      yup.object().shape({
        email: emailValidationForm(
          'profile.emailValidationError.invalidEmail',
        ).required(t('profile.emailValidationError.required')),
        firstName: yup
          .string()
          .strict(true)
          .trim()
          .matches(/^[a-zA-Z ]*$/, t('profile.firstNameError.invalidName'))
          .required(t('profile.firstNameError.required')),
        lastName: yup
          .string()
          .strict(true)
          .trim()
          .matches(/^[a-zA-Z ]*$/, t('profile.lastNameError.invalidName'))
          .required(t('profile.lastNameError.required')),
        dateOfBirth: yup
          .date()
          .max(new Date(), t('profile.dateOfBirthVaidationError'))
          .nullable(),
      }),
    [],
  );

  const onSubmit = async ({
    email,
    firstName,
    lastName,
    optinMarketing,
    dateOfBirth,
  }) => {
    AuthStore.setEmail(email);
    AuthStore.setUserFirstName(firstName);
    AuthStore.setUserLastName(lastName);
    AuthStore.setOptinMarketing(optinMarketing);
    AuthStore.setDateOfBirth(dateOfBirth);

    try {
      await AuthStore.setMetadata({
        firstName,
        lastName,
        email,
        language: LanguageStore.selectedValue,
        optinMarketing,
        dateOfBirth,
      });
      closeEditProfilePage();
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err);
      }
    }
  };

  useEffect(() => {
    if (AuthStore.isLoggedIn) {
      AuthStore.getMetadata();
    }
  }, [AuthStore.isLoggedIn]);

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';

    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
    };
  }, []);

  const checkIsSubmitButtonDisabled = ({ isValid }) => !isValid;

  const renderBody = () => (
    <div className={classnames(styles.editProfile, styles.editProfilePage)}>
      <Header
        headerTitle={t('profile.editTitle')}
        enforceSmall={isLg}
        handleBackClick={closeEditProfilePage}
      />
      <Formik
        enableReinitialize
        initialValues={{
          firstName: AuthStore.userFirstName,
          lastName: AuthStore.userLastName,
          email: AuthStore.email,
          phone: AuthStore.phone,
          optinMarketing: AuthStore.optinMarketing,
          dateOfBirth: AuthStore.dateOfBirth,
        }}
        validationSchema={getValidateSchema()}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Background className={styles.editProfile}>
            <form
              onSubmit={(event: any) => {
                event.preventDefault();
                formikProps.handleSubmit();
              }}
              className={styles.editProfile}
            >
              <div className={styles.editProfileFormContainer}>
                <CustomInput
                  onChange={formikProps.handleChange}
                  value={formikProps.values.firstName}
                  type={'text'}
                  name={'firstName'}
                  placeholder={t('profile.firstNamePlaceholder')}
                  error={!!formikProps.errors.firstName}
                  errorMessage={formikProps.errors.firstName}
                  testId="first-name-edit-profile"
                  customStyle={{ marginTop: 0 }}
                  withAnimation
                />
                <CustomInput
                  onChange={formikProps.handleChange}
                  value={formikProps.values.lastName}
                  type={'text'}
                  name={'lastName'}
                  placeholder={t('profile.lastNamePlaceholder')}
                  error={!!formikProps.errors.lastName}
                  errorMessage={formikProps.errors.lastName}
                  testId="last-name-edit-profile"
                  customStyle={{ marginTop: 0 }}
                  withAnimation
                />
                <CustomInput
                  onChange={formikProps.handleChange}
                  value={formikProps.values.email}
                  type={'email'}
                  name={'email'}
                  placeholder={t('profile.emailPlaceholder')}
                  error={!!formikProps.errors.email}
                  errorMessage={formikProps.errors.email}
                  testId="email-edit-profile"
                  customStyle={{ marginTop: 0 }}
                  withAnimation
                />
                <CustomPhoneInput
                  placeholder={t('profile.phonePlaceholder')}
                  formikProps={formikProps}
                  disabled
                  customStyle={{ marginTop: 32 }}
                  testId="phone-edit-profile"
                />
                <DatePickerComponent
                  fieldName="dateOfBirth"
                  placeholder={t('profile.dateOfBirthPlaceholder')}
                  maxDate={new Date()}
                  minDate={new Date(minYear, null)}
                  maxYear={maxYear}
                  minYear={minYear}
                />
                <RadioButtonGroup
                  value={formikProps.values.optinMarketing}
                  formikProps={formikProps}
                  property={'optinMarketing'}
                  containerClassName={styles.buttonGroupContainer}
                  itemKeyForCheck="value"
                  radioButtonStyle={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19px',
                  }}
                  data={[
                    {
                      label: t('profile.marketingOptIn'),
                      value: '1',
                      content: config.marketingOptIn ? (
                        <SanitizedHtml html={config.marketingOptIn} />
                      ) : null,
                    },
                    {
                      label: t('profile.marketingOptOut'),
                      value: '0',
                      content: config.marketingOptOut ? (
                        <SanitizedHtml html={config.marketingOptOut} />
                      ) : null,
                    },
                  ]}
                />
                <div
                  data-test-id="edit-profile-page-delete-button"
                  style={config.linkStyle}
                  className={styles.deleteAccount}
                  onClick={onShowDeleteModal}
                >
                  <Text value="profile.deleteLabel" />
                </div>
              </div>
              <PrimaryButton
                className={styles.editProfileFormButton}
                disabled={checkIsSubmitButtonDisabled(formikProps)}
                testId="submit-edit-profile"
              >
                <Text value={'profile.submitButton'} />
              </PrimaryButton>
              {showDeleteModal && (
                <PopUp testId="edit-profile-page" onClose={onHideDeleteModal}>
                  {config.accountDeletionInstruction ? (
                    <SanitizedHtml html={config.accountDeletionInstruction} />
                  ) : (
                    <Trans i18nKey="profile.deleteAccountInformation">
                      In order for us to delete your account, please email
                      MyCheck at:
                      <a href="mailto: support@mycheckapp.com">
                        support@mycheckapp.com
                      </a>
                    </Trans>
                  )}
                </PopUp>
              )}
            </form>
          </Background>
        )}
      </Formik>
    </div>
  );

  if (isLg) {
    return (
      <Overlay
        testId="edit-profile-page"
        height={712}
        onClickOutside={handleBackClick}
      >
        {renderBody()}
      </Overlay>
    );
  }

  return renderBody();
});

EditProfilePage.displayName = 'EditProfilePage';
