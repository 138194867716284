import React, { useCallback, useMemo } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import Flag from 'react-world-flags';

import { MyCheckApp, StorageService } from 'mycheck-core';

import styles from './LangList.module.scss';
import { LanguageOption } from '../../types/LanguageTypes';

type LangListElementType = {
  lang: LanguageOption;
  selected: LanguageOption;
  handleSelectedLanguage: (value: LanguageOption) => void;
};

export const LangListElement: React.FC<LangListElementType> = ({
  lang,
  selected,
  handleSelectedLanguage,
}) => {
  const onPress = useCallback(() => {
    handleSelectedLanguage(lang);
    StorageService.setValue(
      StorageService.STORAGE_KEYS.PICKER_PREFERENCE,
      lang,
    );
  }, [lang]);

  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const langCode = lang.value.split('_')[1];

  return (
    <div
      data-test-id="lang-list-element"
      className={classnames(
        styles.langListItem,
        selected.value === lang.value ? styles.langListItemSelected : '',
      )}
      onClick={onPress}
    >
      <Flag code={langCode} width={24} className={styles.langListItemFlag} />
      <span
        style={selected.value === lang.value ? { color: config.primary } : {}}
      >
        {lang.name}
      </span>
    </div>
  );
};
