import React from 'react';
import { Loader, PriceFormatter } from '@components';
import styles from '../../KioskCheckout.module.scss';
import { useTranslation } from 'mycheck-core';
import { useOutletContext } from 'react-router-dom';
import { KioskOutletContext } from 'components/Kiosk/types';

export const KioskCheckoutLoading = () => {
  const { t } = useTranslation();
  const { obfuscatedEmailReceipt, total } =
    useOutletContext<
      Pick<KioskOutletContext, 'obfuscatedEmailReceipt' | 'total'>
    >();
  return (
    <>
      {obfuscatedEmailReceipt && (
        <h3 className={styles.emailTitle}>
          {t('kiosk.kioskCheckoutBody.emailTitle')}&nbsp;
          {obfuscatedEmailReceipt}
        </h3>
      )}
      <h1 className={styles.title}>
        {t('kiosk.kioskCheckoutBody.title')}&nbsp;
        <PriceFormatter value={Number(total)} withoutSpace />
      </h1>
      <div
        className={styles.loaderContainer}
        style={{ marginBottom: obfuscatedEmailReceipt ? '96px' : '112px' }}
      >
        <Loader withOverlay={false} />
      </div>
      <p className={styles.captionTitle}>
        {t('kiosk.kioskCheckoutLoading.captionTitle')}
      </p>
      <p className={styles.caption}>
        {t('kiosk.kioskCheckoutLoading.caption')}
      </p>
    </>
  );
};
