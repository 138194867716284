import React from 'react';
import { CustomButton, CustomIcon } from '@components';
import styles from '../../KioskCheckout.module.scss';
import { useTranslation } from 'mycheck-core';
import { useOutletContext } from 'react-router-dom';
import { KioskOutletContext } from 'components/Kiosk/types';

export const KioskCheckoutWentWrong = () => {
  const { t } = useTranslation();
  const { startOver, cancel } =
    useOutletContext<Pick<KioskOutletContext, 'startOver' | 'cancel'>>();

  return (
    <>
      <CustomIcon
        name="warning"
        style={{
          color: '#C6C6C6',
          width: '230px',
          margin: '80px 0 60px',
        }}
      />
      <p className={styles.captionTitle}>
        {t('kiosk.kioskCheckoutWentWrong.captionTitle')}
      </p>
      <p className={styles.captionIssue}>
        {t('kiosk.kioskCheckoutWentWrong.caption')}
      </p>
      <CustomButton
        className={styles.buttonAction}
        text={t('kiosk.kioskCheckoutWentWrong.primaryButton')}
        testId="kiosk-checkout-warning-button"
        handleButtonClick={startOver}
      />
      <CustomButton
        className={styles.button}
        text={t('kiosk.kioskCheckoutWentWrong.secondaryButton')}
        testId="kiosk-checkout-warning-button"
        isBorderColor
        handleButtonClick={cancel}
      />
    </>
  );
};
