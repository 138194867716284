import React, { useMemo, type ReactNode } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import styles from './CustomButton.module.scss';

interface Props {
  text: string | ReactNode;
  handleButtonClick?: () => void;
  className?: string;
  backgroundColor?: string;
  isBorderColor?: boolean;
  testId?: string;
  type?: string;
  disabled?: boolean;
}

export const CustomButton: React.FC<Props> = ({
  text,
  handleButtonClick,
  className,
  backgroundColor,
  isBorderColor = false,
  testId = '',
  type,
  disabled = false,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  if (type === 'submit') {
    return (
      <button
        type="submit"
        className={classnames(styles.customButton, className)}
        style={{ backgroundColor: backgroundColor || config.primary }}
        data-test-id={testId}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }

  return (
    <button
      onClick={handleButtonClick}
      className={classnames(styles.customButton, className)}
      style={{
        backgroundColor: backgroundColor || config.primary,
        ...(isBorderColor ? { borderColor: config.primary } : {}),
      }}
      data-test-id={testId}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
