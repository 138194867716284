export enum IdleTimeoutType {
  Auto = 'Auto',
  Modal = 'Modal',
  None = 'None',
}

export enum KioskPostMessageEvent {
  RedirectToLandingPage = 'redirectToLandingPage',
}

export type IdleRouteConfigType = {
  [key in IdleTimeoutType]?: string[];
};
