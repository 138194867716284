import React, { type CSSProperties } from 'react';

import { capitalizeFirstLetter, useTranslation } from 'mycheck-core';
import { CustomIcon, Overlay } from '@components';

import styles from '../../Picker/Picker.module.scss';

import classnames from 'classnames';
import {
  type TimePickerDates,
  type TimePickerTimes,
  type TimePickerStep,
  type TimePickerValues,
  type TimePickerOnChangeParameters,
} from '../types';

type TimePickerDesktopProps = {
  handleChange: (
    name: TimePickerOnChangeParameters['name'],
    value: TimePickerOnChangeParameters['value'],
  ) => void;
  onSubmit: (time: string) => void;
  onClose: () => void;
  isAsap: boolean;
  dates: TimePickerDates[];
  times: TimePickerTimes[];
  values: TimePickerValues;
  style?: CSSProperties;
  locationHours?: boolean;
  hideClose?: boolean;
  step: TimePickerStep;
  setStep: (step: TimePickerStep) => void;
  locationTimesList: TimePickerTimes[];
};

export const TimePickerDesktop: React.FC<TimePickerDesktopProps> = ({
  handleChange,
  onSubmit,
  onClose,
  isAsap,
  dates,
  times,
  values,
  style,
  locationHours,
  hideClose,
  step,
  setStep,
  locationTimesList,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Overlay
        withoutModal
        overlayStyle={{ top: 128, backdropFilter: 'blur(2px)' }}
        onClickOutside={() => {
          if (!hideClose) {
            onClose();
          }
        }}
        testId="time-picker"
      >
        <div className={styles.picker} style={style}>
          <div className={styles.pickerHeader}>
            {!hideClose && (
              <div onClick={onClose}>
                <CustomIcon
                  name={'x'}
                  className={styles.pickerHeaderCloseIcon}
                ></CustomIcon>
              </div>
            )}
            <div className={styles.pickerHeaderTitle}>
              {' '}
              {step === 'day'
                ? t('timeSelection.selectDay')
                : capitalizeFirstLetter(
                    t('timeSelection.selectTimeTitle').toLowerCase(),
                  )}
            </div>
          </div>

          {step === 'day' && (
            <div
              className={classnames(
                styles.pickerList,
                dates.length > 4 ? styles.pickerListLinear : '',
              )}
              style={dates.length > 4 ? { paddingBottom: 100 } : {}}
            >
              {dates.map((dateValue) => (
                <div
                  key={dateValue.valueDate}
                  id={dateValue.valueDate}
                  className={classnames(
                    styles.pickerListItem,
                    values.date === dateValue.valueDate || isAsap
                      ? styles.pickerListItemActive
                      : '',
                  )}
                  onClick={() => {
                    handleChange('day', dateValue.valueDate);
                  }}
                  data-test-id={dateValue.label}
                >
                  {dateValue.label}
                </div>
              ))}
            </div>
          )}

          {step === 'time' && (
            <div
              className={classnames(
                styles.pickerList,
                times.length > 15 ? styles.pickerListLinear : '',
              )}
              style={times.length > 15 ? { paddingBottom: 100 } : {}}
            >
              {(locationHours ? locationTimesList : times).map((time) => (
                <div
                  key={time.value}
                  id={time.value}
                  className={classnames(
                    styles.pickerListFlexItem,
                    values.time === time.value || isAsap
                      ? styles.pickerListFlexItemActive
                      : '',
                  )}
                  onClick={() => {
                    handleChange('time', time.value);
                  }}
                  data-test-id={time.label}
                >
                  {time.label}
                </div>
              ))}
            </div>
          )}

          <div className={styles.pickerFooter}>
            {step === 'day' && (
              <div
                className={styles.pickerFooterRight}
                onClick={() => setStep('time')}
                data-test-id="time-picker-next"
              >
                {t('general.next')}
              </div>
            )}
            {step === 'time' && (
              <div
                className={styles.pickerFooterRight}
                onClick={() => {
                  if (isAsap) {
                    onClose();
                  } else {
                    onSubmit(null);
                  }
                }}
                data-test-id="time-picker-save"
              >
                {t('general.save').toLowerCase()}
              </div>
            )}
            {step === 'time' && (
              <div
                className={styles.pickerFooterLeft}
                onClick={() => setStep('day')}
              >
                {t('general.back')}
              </div>
            )}
          </div>
        </div>
      </Overlay>
    </>
  );
};
