import React, { useMemo, useState, type CSSProperties } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  GaService,
  MyCheckApp,
  useAuthStore,
  useLanguageLoader,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon, Overlay } from '@components';

import { LangList } from './LangList/LangList';
import styles from './LanguagePicker.module.scss';
import { LanguageOption } from '../types/LanguageTypes';

interface Props {
  onHomePage?: boolean;
  primaryColor?: boolean;
  pickerRight?: number;
  overlayStyle?: CSSProperties;
}

export const LangPicker: React.FC<Props> = ({
  onHomePage = false,
  primaryColor = false,
  pickerRight,
  overlayStyle,
}) => {
  const {
    selectedLang,
    availableLanguages,
    loadTranslation,
    isLanguagePickAvailable,
  } = useLanguageLoader();
  const { isLg } = useWindowSize();
  const AuthStore = useAuthStore();
  const location = useLocation();

  const [pickerOpen, setPickerOpen] = useState(false);

  const configStyles = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const style = get(
      _config,
      onHomePage ? 'home.style.headerText' : 'general.desktopHeaderText',
      {},
    );

    const primary = get(_config, 'palette.primary', {});

    const icons =
      primaryColor || (isLg && !onHomePage) ? { color: primary } : {};

    return {
      style,
      primary,
      icons,
      supportMembers,
    };
  }, [location.search]);

  const handleSelectedLanguage = async (selectedLanguage: LanguageOption) => {
    setPickerOpen(false);
    GaService.instance.sendEvent({
      category: 'Ordering - Home',
      action: 'Language',
      label: selectedLanguage.name,
    });
    await loadTranslation(selectedLanguage);
    if (AuthStore.isLoggedIn || AuthStore.isGuestLoggedIn) {
      await AuthStore.setMetadata({ language: selectedLanguage.value });
    }
  };

  if (!isLanguagePickAvailable()) {
    return null;
  }

  const testStyle: CSSProperties = {
    marginTop: configStyles.supportMembers ? -5 : undefined,
    color: onHomePage ? '#ffffff' : undefined,
  };

  return (
    <>
      <div className={styles.root} onClick={() => setPickerOpen(!pickerOpen)}>
        <CustomIcon
          name="languagePicker"
          style={{ ...configStyles.icons, width: 16 }}
        />

        {isLg && (
          <div className={styles.rootText} style={testStyle}>
            {selectedLang.name}
            <CustomIcon
              name="arrowDown"
              className={styles.rootArrowIcon}
              style={configStyles.icons}
            />
          </div>
        )}
      </div>
      {pickerOpen && (
        <Overlay
          withoutModal={true}
          onClickOutside={() => {
            setTimeout(() => {
              setPickerOpen(false);
            }, 500);
          }}
          overlayStyle={{ ...overlayStyle, backgroundColor: 'transparent' }}
        >
          <div
            className={styles.rootPicker}
            style={
              onHomePage && isLg
                ? { right: 24, top: 66 }
                : { right: pickerRight }
            }
          >
            <LangList
              selectedLanguage={selectedLang}
              languages={availableLanguages}
              handleSelectedLanguage={handleSelectedLanguage}
            />
          </div>
        </Overlay>
      )}
    </>
  );
};
