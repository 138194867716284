export interface KioskOutletContext {
  backToMenu: () => void;
  cancel: () => Promise<void>;
  cancelTransaction: () => Promise<void>;
  isCheckoutFetching: boolean;
  obfuscatedEmailReceipt: string;
  startOver: () => void;
  submitOrder: (paymentMethod: string) => Promise<void>;
  total: string | number;
}

export enum KioskPostMessageEvent {
  RedirectToLandingPage = 'redirectToLandingPage',
  RedirectToThankYou = 'redirectToThankYou',
  Error = 'Could not post message to react native webView event',
}
