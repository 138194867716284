const censorMapper = (emailElem: string, index: number) => {
  const splittedString = emailElem.split('.');
  return splittedString.reduce((acc, elem, textIndex, arr) => {
    acc = textIndex > 0 ? acc.concat('', '.') : acc;
    return (
      acc +
      (index === 1 && textIndex === arr.length - 1
        ? elem
        : elem[0] + '*'.repeat(elem.length - 1))
    );
  }, '');
};

export const obfuscateEmailAddress = (email: string): string => {
  const splittedString = email.split('@');
  return splittedString.map(censorMapper).join('@');
};
