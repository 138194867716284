import React, { useMemo } from 'react';
import {
  useCheckoutStore,
  useLocation,
  useNavigation,
  useTranslation,
} from 'mycheck-core';
import { MyCheckApp } from 'mycheck-core';
import get from 'lodash/get';

import styles from './KioskCheckout.module.scss';
import { KioskCalculationSection } from './components/CalculationSection/KioskCalculationSection';
import { Loader } from '@components';
import { Outlet } from 'react-router-dom';
import { CardSummary } from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import {
  getKioskObfuscatedEmailReceipt,
  getKioskFailedFlow,
  setKioskFlowFailed,
} from 'core/core/store/kiosk/slice';
import { useAppDispatch, useAppSelector } from 'core/core/store/store';

interface KioskCheckoutProps {
  isCheckoutFetching: boolean;
  localPendingCalculations: boolean;
  submitOrder: (paymentMethod: string) => Promise<void>;
  summaryObject: Partial<CardSummary>;
}

export const KioskCheckout: React.FC<KioskCheckoutProps> = ({
  isCheckoutFetching,
  localPendingCalculations,
  submitOrder,
  summaryObject,
}) => {
  const obfuscatedEmailReceipt = useAppSelector(getKioskObfuscatedEmailReceipt);
  const isFlowFailed = useAppSelector(getKioskFailedFlow);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const CheckoutStore = useCheckoutStore();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const isLoading = localPendingCalculations;

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const startOver = () => {
    dispatch(setKioskFlowFailed(false));
    navigation.push('/menu/checkout');
  };

  const backToMenu = () => {
    navigation.push('/menu');
  };

  const cancel = async () => {
    dispatch(setKioskFlowFailed(false));
    backToMenu();
  };

  const cancelTransaction = async () => {
    try {
      await CheckoutStore.cancelTransaction();
    } catch {
      dispatch(setKioskFlowFailed(true));
      navigation.push('wentWrong');
    }
  };

  return (
    <div className={styles.root}>
      <header
        className={styles.header}
        style={{ backgroundColor: config.primary }}
      >
        {t('kiosk.kioskCheckout.title')}
      </header>
      <main>
        {isLoading && (
          <Loader
            withOverlay={false}
            label={t('kiosk.kioskCheckout.loadingLabel')}
            labelStyle={{ fontSize: '24px', lineHeight: '32px' }}
          />
        )}
        {!isLoading && (
          <>
            {!isFlowFailed && (
              <KioskCalculationSection summaryObject={summaryObject} />
            )}

            <section className={styles.container}>
              <Outlet
                context={{
                  backToMenu,
                  cancel,
                  cancelTransaction,
                  isCheckoutFetching,
                  obfuscatedEmailReceipt,
                  startOver,
                  submitOrder,
                  total: summaryObject.total,
                }}
              />
            </section>
          </>
        )}
      </main>
    </div>
  );
};
