export const enum Experience {
  Market = 'STORE',
  Delivery = 'DELIVERY',
  TabletOrdering = 'DINEIN_TABLET_WAITER_CHECKOUT',
  PickUp = 'PICKUP',
  Quarantine = 'QUARANTINE',
  RoomService = 'ROOM_SERVICE',
  CondoDelivery = 'CONDO_DELIVERY',
  DineIn = 'DINE_IN',
  ViewMenu = 'VIEW_MENU',
  ClickAndCollect = 'CLICK_AND_COLLECT',
  PoolService = 'POOL_SERVICE',
  DeliveryToRoom = 'DELIVER_TO_ROOM',
  InRoomDining = 'IN_ROOM_DINING',
  ConferenceRooms = 'CONFERENCE_ROOMS',
  Table = 'TABLE',
  TakeAway = 'TAKE_AWAY',
  Kiosk = 'KIOSK',
}
