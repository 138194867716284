import React from 'react';

import { observer } from 'mobx-react';

import { useLocationStore } from 'mycheck-core';

import { currencyFormatter } from '../../core/core/helpers/currencyFormatter';

import styles from './PriceFormatter.module.scss';

type PriceFormatterType = {
  value: number;
  currency?: string;
  withoutSpace?: boolean;
};

export const PriceFormatter: React.FC<PriceFormatterType> = observer(
  ({ value = 0, currency, withoutSpace = false }) => {
    const LocationStore = useLocationStore();

    if (!currency && !LocationStore.selectedRestaurant.currency) {
      return null;
    }

    const currentCurrency = LocationStore.currenciesList.find(
      (item) =>
        item.code === (currency || LocationStore.selectedRestaurant.currency),
    );
    const price = currencyFormatter(value, currentCurrency, withoutSpace);

    return <span className={styles.priceFormatter}>{price}</span>;
  },
);

PriceFormatter.displayName = 'PriceFormatter';
