import React, { useMemo } from 'react';

import {
  BrowserRouter,
  Navigate,
  Route,
  RouteProps,
  Routes,
} from 'react-router-dom';

import { ModalTrackersAnchor, MyCheckApp, useWindowSize } from 'mycheck-core';
import { CheckoutPage } from '../../../plugins/CheckoutPlugin/pages/CheckoutPage/CheckoutPage';
import { ConfirmPage } from '../../../plugins/CheckoutPlugin/pages/ConfirmPage/ConfirmPage';
import { MoreInfoModal } from '../../../plugins/LocationPlugin/components/LocationContent/MoreInfoModal';
import { HomePage } from '../../../plugins/LocationPlugin/pages/HomePage/HomePage';
import { LocationPage } from '../../../plugins/LocationPlugin/pages/LocationPage/LocationPage';
import { ItemPage } from '../../../plugins/MenuPlugin/pages/ItemPage/ItemPage';
import { OrderPage } from '../../../plugins/MenuPlugin/pages/OrderPage/OrderPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { ParamsService } from '../service/ParamsService';
import { StoreLoaderService } from '../service/StoreLoaderService';
import { BackHandler } from './BackHandler/BackHandler';
import { ModalNavigator } from './ModalNavigator';
import MenuPage from 'plugins/MenuPlugin/pages/MenuPage/common/MenuPage';
import OrderTimeoutService from '../service/OrderTimeoutService';
import OrderTimeoutPage from 'core/pages/OrderTimeoutPage/OrderTimeoutPage';
import {
  KioskCancelOrder,
  KioskCancelTransaction,
  KioskCheckoutBody,
  KioskCheckoutLoading,
  KioskCheckoutTapBody,
  KioskCheckoutWentWrong,
} from '@components';

const setBasePath = () => {
  const { name: accountName } = MyCheckApp.instance.getBusinessDetails();
  const first = window.location.pathname;
  const prefix = first.split('/')?.[1] || '';

  if (prefix.toLocaleLowerCase() === accountName.toLocaleLowerCase()) {
    return accountName;
  }

  return '';
};

const renderMultiRoutes = ({
  element,
  paths,
  ...rest
}: RouteProps & { paths: string[] }) =>
  paths.map((path) => (
    <Route key={path} path={path} {...rest} element={element} />
  ));

const menuRoutes = () => {
  return (
    <>
      <Route path="/menu/order" element={<OrderPage />} />
      <Route path="/menu/confirm" element={<ConfirmPage />} />
      <Route path="/menu/checkout" element={<CheckoutPage />}>
        <Route index element={<KioskCheckoutBody />} />
        <Route
          path="/menu/checkout/loading"
          element={<KioskCheckoutLoading />}
        />
        <Route
          path="/menu/checkout/tapCard"
          element={<KioskCheckoutTapBody />}
        />
        <Route
          path="/menu/checkout/wentWrong"
          element={<KioskCheckoutWentWrong />}
        />
      </Route>
      <Route
        path="/menu/cancelTransaction"
        element={<KioskCancelTransaction />}
      />
      <Route path="/menu/cancelOrder" element={<KioskCancelOrder />} />
      {renderMultiRoutes({
        paths: ['/menu/:id/:editId', '/menu/:id'],
        element: <ItemPage />,
      })}
    </>
  );
};

export const RootNavigation: React.FC = () => {
  const { isLg } = useWindowSize();
  const config = useMemo(() => {
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();

    return {
      blockHomePage,
    };
  }, [window.location.search]);

  return (
    <BrowserRouter basename={setBasePath()}>
      <ModalTrackersAnchor>
        <StoreLoaderService>
          <ParamsService>
            <OrderTimeoutService>
              <BackHandler />
              <ModalNavigator>
                <Routes>
                  <Route
                    path="/order"
                    element={<Navigate to="/menu/order" replace />}
                  />
                  {isLg && (
                    <Route path="/menu" element={<MenuPage />}>
                      {menuRoutes()}
                    </Route>
                  )}
                  {!isLg && (
                    <>
                      <Route path="/menu" element={<MenuPage />} />
                      {menuRoutes()}
                    </>
                  )}
                  <Route
                    path="/location/:id/info"
                    element={<MoreInfoModal />}
                  />
                  <Route path="/location" element={<LocationPage />} />
                  {!config.blockHomePage &&
                    renderMultiRoutes({
                      paths: ['/', '/index', '/index.html'],
                      element: <HomePage />,
                    })}
                  <Route path="/order-timeout" element={<OrderTimeoutPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </ModalNavigator>
            </OrderTimeoutService>
          </ParamsService>
        </StoreLoaderService>
      </ModalTrackersAnchor>
    </BrowserRouter>
  );
};
